import { StatusBar } from 'expo-status-bar';
import { StyleSheet, View } from 'react-native';
import Login from './screens/auth/Login/Login';
import { Amplify } from 'aws-amplify'
import awsconfig from './src/aws-exports'
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";
import { Provider } from 'react-redux';
import store from './src/redux/store';
import { LogBox, useColorScheme } from 'react-native';

Amplify.configure(awsconfig)

export default function App() {
  const colorScheme = useColorScheme();

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: colorScheme === 'dark' ? 'black' : 'white',
    },
  });

  return (
      <Provider store={store}>
      <View style={[styles.container]}>
        <Login />
        <StatusBar style="auto" />
      </View>
    </Provider>
  );
}
LogBox.ignoreAllLogs();

LogBox.ignoreLogs(['Warning: ...']);
serviceWorkerRegistration.register();