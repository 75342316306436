import { useCallback, useEffect } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Modal,
  ActivityIndicator,
  useColorScheme,
  Image,
} from 'react-native';
import Dashboard from '../../Dashboard';
import Register from '../Register/Register';
import ForgotPassword from '../Forgot/ForgotPassword';
import { Auth } from 'aws-amplify';
import {
  setLoginUsername,
  setLoginPassword,
  setModalLoginVisible,
  setModalRegisterVisible,
  setModalForgotPasswordVisible,
  setIsLoading,
  setUsernameValid,
  setPasswordValid,
  setErrorText,
} from '../../../src/redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import createStyles from './LoginStyles';
import logo from '../../../assets/logo.png'

export default function Login() {
  const colorScheme = useColorScheme();
  const styles = createStyles(colorScheme);

  const dispatch = useDispatch();
  const {
    loginUsername,
    loginPassword,
    modalLoginVisible,
    modalRegisterVisible,
    modalForgotPasswordVisible,
    isLoading,
    isUsernameValid,
    isPasswordValid,
    errorText,
  } = useSelector((state) => ({
    loginUsername: state.loginUsername,
    loginPassword: state.loginPassword,
    modalLoginVisible: state.modalLoginVisible,
    modalRegisterVisible: state.modalRegisterVisible,
    modalForgotPasswordVisible: state.modalForgotPasswordVisible,
    isLoading: state.isLoading,
    isUsernameValid: state.isUsernameValid,
    isPasswordValid: state.isPasswordValid,
    errorText: state.errorText,
  }));

  const usernameRegex = /^[a-zA-Z0-9]{3,20}$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const validateUsername = (username) => {
    const isValid = usernameRegex.test(username);
    dispatch(setUsernameValid(isValid));
    return isValid;
  };

  const validatePassword = (password) => {
    const isValid = passwordRegex.test(password);
    dispatch(setPasswordValid(isValid));
    return isValid;
  };

  const handleUsernameChange = (text) => {
    dispatch(setLoginUsername(text));
  };

  const handlePasswordChange = (text) => {
    dispatch(setLoginPassword(text));
  };

  const login = async () => {
    const isUsernameValid = validateUsername(loginUsername);
    const isPasswordValid = validatePassword(loginPassword);

    if (!isUsernameValid || !isPasswordValid) {
      dispatch(setErrorText('Пожалуйста, исправьте ошибки в форме.'));
      return;
    }

    try {
      dispatch(setIsLoading(true));
      await Auth.signIn(loginUsername, loginPassword);
      dispatch(setModalLoginVisible(true));
    } catch (error) {
      if (error.code === 'UserNotFoundException') {
        dispatch(setErrorText('Пользователь не существует. Пожалуйста, проверьте введенные данные.'));
      } else if (error.code === 'NotAuthorizedException') {
        dispatch(setErrorText('Неверный логин или пароль. Пожалуйста, проверьте введенные данные.'));
      } else {
        dispatch(setErrorText('Произошла ошибка входа. Пожалуйста, повторите попытку позже.'));
      }
      console.error(error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const registerNavigation = useCallback(() => {
    dispatch(setModalRegisterVisible(true));
  }, [dispatch]);

  const forgotPasswordNavigation = () => {
    dispatch(setModalForgotPasswordVisible(true));
  };

  const closeModal = () => {
    dispatch(setModalLoginVisible(false));
    dispatch(setModalRegisterVisible(false));
    dispatch(setModalForgotPasswordVisible(false));
  };

  useEffect(() => {
    const checkCurrentUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          dispatch(setModalLoginVisible(true));
        } else {
          dispatch(setModalLoginVisible(false));
        }
      } catch (error) {
        console.error('No authenticated user:', error);
      }
    };

    checkCurrentUser();
  }, [dispatch]);

  return (
    <View style={styles.container}>
      <Image source={logo} style={styles.logoImage} />
      <Text style={styles.logo}>Поделись...</Text>
      <br />
      <br />
      <Text style={styles.logoSmall}>Быстро. Безопасно. Круто.</Text>
      <br />
      <br />
      <TextInput
        style={[
          styles.input, { outline: 'none' },
          !isUsernameValid && { borderColor: 'red' },
        ]}
        placeholder="Никнейм"
        placeholderTextColor={styles.input.color}
        value={loginUsername}
        onChangeText={handleUsernameChange}
      />
      {!isUsernameValid && (
        <Text style={styles.errorText}>Никнейм должен содержать 3-20 букв или цифр.</Text>
      )}
      <TextInput
        style={[
          styles.input, { outline: 'none' },
          !isPasswordValid && { borderColor: 'red' },
        ]}
        placeholder="Пароль"
        secureTextEntry={true}
        placeholderTextColor={styles.input.color}
        value={loginPassword}
        onChangeText={handlePasswordChange}
      />
      {!isPasswordValid && (
        <Text style={styles.errorText}>
          Пароль должен содержать как минимум 8 символов, включая заглавную букву, строчную букву и цифру.
        </Text>
      )}
      <TouchableOpacity onPress={registerNavigation} activeOpacity={0.7}>
        <Text style={styles.registerText}>Нет аккаунта? Регистрация</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={forgotPasswordNavigation} activeOpacity={0.7}>
        <Text style={styles.registerText}>Забыли пароль?</Text>
      </TouchableOpacity>
      {errorText && <Text style={styles.errorText}>{errorText}</Text>}
      <TouchableOpacity
        onPress={login}
        activeOpacity={0.7}
        disabled={!loginUsername || !loginPassword}
        style={[
          styles.button,
          (!loginUsername || !loginPassword) ? styles.disabledButton : null,
        ]}
      >
        {isLoading ? (
          <ActivityIndicator size={27} color="lightgrey" />
        ) : (
          <Text style={styles.buttonText}>Вход</Text>
        )}
      </TouchableOpacity>
      <Modal visible={modalLoginVisible} animationType="slide" transparent={true}>
        <View style={styles.modalContainer}>
          <Dashboard closeModal={closeModal} />
        </View>
      </Modal>
      <Modal visible={modalRegisterVisible} animationType="slide" transparent={true}>
        <View style={styles.modalContainer}>
          <Register closeModal={closeModal} />
        </View>
      </Modal>
      <Modal visible={modalForgotPasswordVisible} animationType="slide" transparent={true}>
        <View style={styles.modalContainer}>
          <ForgotPassword closeModal={closeModal} />
        </View>
      </Modal>
    </View>
  );
}