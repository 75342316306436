import Svg, { Path } from 'react-native-svg';

const PinIcon = ({ width = 92.25, height = 122.88, color = 'black' }) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 92.25 122.88"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <Path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.51,106.28c-5.59,6.13-12.1,11.62-19.41,16.06c-0.9,0.66-2.12,0.74-3.12,0.1 
      c-10.8-6.87-19.87-15.12-27-24.09C9.14,86.01,2.95,72.33,0.83,59.15c-2.16-13.36-0.14-26.22,6.51-36.67 
      c2.62-4.13,5.97-7.89,10.05-11.14C26.77,3.87,37.48-0.08,48.16,0c10.28,0.08,20.43,3.91,29.2,11.92c3.08,2.8,5.67,6.01,7.79,9.49 
      c7.15,11.78,8.69,26.8,5.55,42.02c-3.1,15.04-10.8,30.32-22.19,42.82V106.28L68.51,106.28z M46.12,23.76 
      c12.68,0,22.95,10.28,22.95,22.95c0,12.68-10.28,22.95-22.95,22.95c-12.68,0-22.95-10.27-22.95-22.95 
      C23.16,34.03,33.44,23.76,46.12,23.76L46.12,23.76z"
    />
  </Svg>
);

export default PinIcon;
