import { TextInput, useColorScheme } from 'react-native';
import createStyles from './SearchInputStyles';

export default function SearchInput({ searchQuery, setSearchQuery }) {
  const colorScheme = useColorScheme();
  const styles = createStyles(colorScheme);

  return (
    <>
      <TextInput
        style={[styles.component, { outline: 'none' }]}
        placeholder='⌕ Поиск'
        color={colorScheme === 'dark' ? 'lightgrey' : 'black'}
        placeholderTextColor={colorScheme === 'dark' ? 'rgba(70, 61, 103, 1)' : 'rgba(0, 0, 0, 0.17)'}
        value={searchQuery}
        onChangeText={(text) => setSearchQuery(text)}
      />
    </>
  );
}