import Svg, { Path } from 'react-native-svg';

const EmailIcon = ({ width = 122.88, height = 122.88, color = 'black' }) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 122.88 122.88"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      fill={color}
      fillRule="evenodd"
      d="M25.2,0H97.68a25.27,25.27,0,0,1,25.2,25.2V97.68a25.27,25.27,0,0,1-25.2,25.2H25.2A25.27,25.27,0,0,1,0,97.68V25.2A25.27,25.27,0,0,1,25.2,0Zm4.1,37.59L62,64.26,93.33,37.59ZM27.19,82.48l23.3-23.37-23.3-19V82.48ZM53.06,61.21,29,85.29H93.65l-23-24.08-7.57,6.45h0a1.66,1.66,0,0,1-2.12,0l-7.94-6.48ZM73.2,59.07,95.69,82.66V39.9L73.2,59.07Z"
    />
  </Svg>
);

export default EmailIcon;
