/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:220404d3-424a-46ba-8532-b3f011012c64",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_hNnDAW6Js",
    "aws_user_pools_web_client_id": "62qni9jg53ovu0upr4uk4713vq",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://i6gyx2mhnze7fcap7mxipmvxpi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-2m4yja5iwfe77jyquqeqvfpvuu",
    "aws_content_delivery_bucket_region": "us-east-1", 
};


export default awsmobile;
