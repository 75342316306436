import { memo } from 'react';
import { View, Text, Image, useColorScheme } from 'react-native';
import ShieldIcon from '../../icons/ShieldIcon';
import createStyles from './CardItemStyles';

function CardItem({ name, phone, company, position, logo }) {
  const colorScheme = useColorScheme();
  const styles = createStyles(colorScheme);

  const isPrivate = name && name.includes('(private)');
  const sanitizedName = name ? name.replace('(private)', '').trim() : '';

  return (
    <View style={styles.shadow}>
      <View style={styles.component}>
        <View style={styles.shadow}>
          <View style={styles.nameContainer}>
            {isPrivate && <ShieldIcon width={16} height={16} color="grey" />}
            <Text style={styles.name}>{sanitizedName}</Text>
          </View>
          <Text style={styles.phone}>{phone}</Text>
          <Text style={styles.company}>{company}</Text>
          <Text style={styles.position}>{position}</Text>
          <Image style={styles.logo} source={{ uri: logo }} />
          <Image style={styles.logoColored} source={{ uri: logo }} />
        </View>
      </View>
    </View>
  );
}

export default memo(CardItem);
