import React from 'react';
import Svg, { Path } from 'react-native-svg';

const ShieldIcon = ({ width = 111.824, height = 122.88, color = 'black' }) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 111.824 122.88"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.715,0c20.867,13.199,39.669,19.466,55.855,17.992c2.838,57.108-18.25,90.841-55.633,104.888
      C19.844,109.718-1.502,77.422,0.083,17.107C19.069,18.103,37.688,14.01,55.715,0L55.715,0L55.715,0z"
    />
  </Svg>
);

export default ShieldIcon;
