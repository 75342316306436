import { Text, View, TouchableOpacity, Modal, useColorScheme } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { setModalAddCardVisible, 
} from '../../../src/redux/actions';
import PlusIcon from '../../icons/PlusIcon';
import createStyles from './GreetingTextStyles';
import ModalContent from "../../Organisms/ModalContent/ModalContent";

export default function GreetingsText({ onCardCreated }) {
    const date = new Date();
    const currentTime = date.getHours();
    const colorScheme = useColorScheme();
  
    const dispatch = useDispatch();
    const isModalVisible = useSelector(state => state.modalAddCardVisible);
  
    // useEffect(() => {
    //   Auth.currentAuthenticatedUser()
    //     .then(user => {
    //       const username = user.username;
    //       presignedUrlRef.current = `https://www.onlinecard.io/${username}`;
    //     })
    //     .catch(err => console.log(err));
    // }, []);
  
    const findGreet = () => {
      if (currentTime >= 5 && currentTime < 8) {
        return "Ранняя пташка!";
      } else if (currentTime >= 8 && currentTime < 12) {
        return "Доброе утро!";
      } else if (currentTime >= 12 && currentTime < 18) {
        return "Добрый день!";
      } else if (currentTime >= 18 && currentTime < 22) {
        return "Добрый вечер!";
      } else {
        return "Пора спать!";
      }
    };

    const toggleModal = () => {
      dispatch(setModalAddCardVisible(!isModalVisible));
    };

    const styles = createStyles(colorScheme);
  
    return (
      <View style={styles.container}>
        <Text style={styles.greeting}>{findGreet()}</Text>
  
        <TouchableOpacity 
          onPress={toggleModal} 
          style={[styles.plusButton, { outline: 'none' }]}
          activeOpacity={0.567}
        >
          <PlusIcon width={24} height={24} color="rgba(70, 61, 103, 0.7)" />
        </TouchableOpacity>
  
        <Modal
          animationType="slide"
          transparent={true}
          visible={isModalVisible}
          onRequestClose={toggleModal}
        >
          <ModalContent onClose={toggleModal} onCardCreated={onCardCreated} />
        </Modal>
      </View>
    );
  }