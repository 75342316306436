import { StyleSheet } from 'react-native';

const createStyles = (colorScheme) => StyleSheet.create({
  component: {
    backgroundColor: colorScheme === 'dark' ? 'rgba(107,76,91, 0.009)' : 'transparent',
    borderWidth: colorScheme === 'dark' ? 1 : 1,
    borderColor: colorScheme === 'dark' ? 'rgba(70, 61, 103, 0.04121)' : 'white',
    borderRadius: 18,
    width: '98%',
    height: 360,
    marginBottom: 12,
    overflow: 'hidden',
    left: '1%',
    shadowColor: colorScheme === 'dark' ? 'rgba(150, 58, 100, 0)' : 'rgba(150, 58, 100, 0.0432)',
    shadowOffset: { width: 0, height: 2110 },
    shadowOpacity: 1, 
    shadowRadius: 33, 
  },
  name: {
    color: colorScheme === 'dark' ? 'rgba(233, 222, 255, .64)' : 'rgba(1,14,36, 0.77)',
    left: 20,
    marginTop: '7.5%',
    letterSpacing: .43,
    fontSize: 33,
    fontWeight: '400',
  },
  errorText: {
    textAlign: 'center',
    fontSize: 16,
    color: 'red'
  },
  nameContainer: {
    flex: 1,
    marginLeft: '4%',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  phone: {
    color: colorScheme === 'dark' ? 'rgba(233, 222, 255, .0831)' : 'rgba(0, 0, 0, 0.081)',
    marginLeft: 20,
    fontSize: 20,
    fontWeight: '600',
    fontStyle: 'italic',
  }, 
  company: {
    color: colorScheme === 'dark' ? 'rgba(233, 222, 255, .567)' : 'rgba(0, 0, 0, 0.3)',
    marginLeft: 20,
    top: 20,
    fontSize: 26,
    fontWeight: '400',
    fontStyle: 'italic',
  },
  position: {
    color: colorScheme === 'dark' ? 'rgba(70, 61, 103, 1)' : 'rgba(0, 0, 0, 0.374)',
    marginLeft: 20,
    letterSpacing: .876,
    top: 20,
    bottom: 20,
    fontSize: 26,
    fontWeight: '300',
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: 18,
    resizeMode: 'stretch',
    opacity: colorScheme === 'dark' ? 0.06 : 0.043,
  },
  backgroundImageGif: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: 18,
    resizeMode: 'fill',
    opacity: colorScheme === 'dark' ? 0.043: 0.056,
  },
  logo: {
    width: '153%', 
    height: '153%', 
    position: 'absolute',
    resizeMode: 'contain', 
    marginLeft: '-8%',
    opacity: .0645,
    marginTop: '-8%',
    tintColor: colorScheme === 'dark' ? 'rgba(200,190, 200, 0.33)' : 'rgba(123,77, 123, 0.33)',
    transform: [{ rotate: '-30deg' }],
  },
  logoInput: {
    color: colorScheme === 'dark' ? 'rgba(70, 61, 103, 1)' : 'rgba(0, 0, 0, 0.374)',
    letterSpacing: .876,
    top: 20,
    bottom: 20,
    fontSize: 18,
    marginLeft: 20,
    marginRight: 20,
    fontWeight: '300',
  },
  logoColored: {
    width: '23%', 
    height: '23%', 
    position: 'absolute',
    right: 10,
    bottom: 0,
    resizeMode: 'contain', 
  },
  saveButton: {
    width: 120,
    height: 70,
    borderRadius: 8,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'orange'
  },
  saveButtonText: {
    color: 'black',
    fontWeight: 600,
    fontSize: 24,
    textAlign: 'center',
  },
  buttonStyles: {
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'row',
    padding: 20,
  },
  buttonNew: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    borderColor: 'rgba(70, 61, 103, 0.3)',
  },
});

export default createStyles;