import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  useColorScheme,
} from 'react-native';
import { Auth, API } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import {
  setRegistrationUsername,
  setRegistrationPassword,
  setRegistrationEmail,
  setRegistrationCode,
  setUsernameValid,
  setPasswordValid,
  setEmailValid,
  setCodeValid,
  setGeneralError,
  setIsFormSubmitted,
  setModalRegisterVisible,
} from '../../../src/redux/actions';
import createStyles from './RegisterStyles';
import { createCard } from '../../../src/graphql/mutations';

export default function Register() {
  const colorScheme = useColorScheme();
  const styles = createStyles(colorScheme);
  const dispatch = useDispatch();

  const {
    registrationUsername,
    registrationPassword,
    registrationEmail,
    registrationCode,
    isUsernameValid,
    isPasswordValid,
    isEmailValid,
    isCodeValid,
    generalError,
    isFormSubmitted,
  } = useSelector((state) => ({
    registrationUsername: state.registrationUsername,
    registrationPassword: state.registrationPassword,
    registrationEmail: state.registrationEmail,
    registrationCode: state.registrationCode,
    isUsernameValid: state.isUsernameValid,
    isPasswordValid: state.isPasswordValid,
    isEmailValid: state.isEmailValid,
    isCodeValid: state.isCodeValid,
    generalError: state.generalError,
    isFormSubmitted: state.isFormSubmitted,
  }));

  const closeModal = () => {
    dispatch(setModalRegisterVisible(false));
  };

  const validateUsername = (username) => {
    const isValid = /^[A-Za-z0-9]{3,20}$/.test(username); // 3-20 букв/цифр
    dispatch(setUsernameValid(isValid));
    return isValid;
  };

  const validatePassword = (password) => {
    const isValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password);
    dispatch(setPasswordValid(isValid));
    return isValid;
  };

  const validateEmail = (email) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email); // Стандартная проверка email
    dispatch(setEmailValid(isValid));
    return isValid;
  };

  const validateCode = (code) => {
    const isValid = code.length === 6;
    dispatch(setCodeValid(isValid));
    return isValid;
  };

  const handleUsernameChange = (text) => {
    dispatch(setRegistrationUsername(text));
    validateUsername(text);
  };

  const handlePasswordChange = (text) => {
    dispatch(setRegistrationPassword(text));
    validatePassword(text);
  };

  const handleEmailChange = (text) => {
    dispatch(setRegistrationEmail(text));
    validateEmail(text);
  };

  const handleCodeChange = (text) => {
    dispatch(setRegistrationCode(text));
    validateCode(text);
  };


  const register = async () => {
    const isUsernameValid = validateUsername(registrationUsername);
    const isPasswordValid = validatePassword(registrationPassword);
    const isEmailValid = validateEmail(registrationEmail);
  
    if (!isUsernameValid || !isPasswordValid || !isEmailValid) {
      dispatch(setGeneralError('Пожалуйста, исправьте ошибки в форме.'));
      return;
    }
  
    const htmlContent = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Business Card</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            background-color: #000000;
            margin: 0;
            padding: 20px;
          }
          button {
            display: block;
            margin: 20px auto;
            padding: 10px 20px;
            background-color: orange;
            color: black;
            font-size: 27px;
            font-weight: bold;
            border: none;
            border-radius: 5px;
            cursor: pointer;
          }
          .card {
            background-color: rgb(19, 19, 19);
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            max-width: 400px;
            margin: auto;
            margin-top: 50px;
          }
          .logo {
            max-width: 50%;
            height: auto;
            margin-bottom: 20px;
          }
          h1, h2, h3 {
            margin: 0;
            font-size: 32px;
            font-weight: bold;
            color: white;
          }
          h2 {
            font-size: 23px;
          }
          p {
            margin: 10px 0;
            color: #d6d6d6;
            font-size: 16px;
          }
        </style>
      </head>
      <body>
        <div class="card">
          <h1>${registrationUsername}</h1>
          <p>${registrationEmail}</p>
        </div>
        <button onclick="saveAsVCard('${registrationUsername}', '${registrationEmail}')">Добавить в контакты</button>
      </body>
      <script>
        function saveAsVCard(name, phone, email) {
          var vCard = 'BEGIN:VCARD\\n' +
            'VERSION:3.0\\n' +
            'FN:' + name + '\\n' +
            'TEL:' + phone + '\\n' +
            'EMAIL:' + email + '\\n' +
            'END:VCARD';
  
          var downloadLink = document.createElement('a');
          downloadLink.href = 'data:text/vcard;charset=utf-8,' + encodeURIComponent(vCard);
          downloadLink.download = 'contact.vcf';
          downloadLink.click();
        }
      </script>
      </html>
    `;
  
    try {
      await Auth.signUp({
        username: registrationUsername,
        password: registrationPassword,
        attributes: {
          email: registrationEmail,
        },
      });
  
      const response = await fetch('https://me-v-1-912dc2a2d5f4.herokuapp.com/upload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer 1231231234565654!~4354453mgdcvjkxcvkmaskd3801947t1yihkp1m-lfm4%$%!3^&^8&^*^&^%$@#!@11`,
        },
        body: JSON.stringify({
          routeName: registrationUsername,
          htmlContent: htmlContent,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error uploading content: ' + response.statusText);
      }
  
      dispatch(setIsFormSubmitted(true));
      dispatch(setGeneralError(''));
  
    } catch (error) {
      dispatch(setGeneralError('Ошибка регистрации или загрузки контента. Пожалуйста, попробуйте снова.'));
      console.log(error);
    }
  };
  
  const verifyCode = async () => {
    const isCodeValid = validateCode(registrationCode);
  
    if (!isCodeValid) {
      dispatch(setGeneralError('Код подтверждения должен содержать 6 цифр.'));
      return;
    }
  
    try {
      await Auth.confirmSignUp(registrationUsername, registrationCode);
  
      const user = await Auth.currentAuthenticatedUser();
  
      await API.graphql({
        query: createCard,
        variables: {
          input: {
            name: user.attributes.nickname,
            phone: '',
            email: user.attributes.email,
          }
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
  
      closeModal();
    } catch (error) {
      dispatch(setGeneralError('Ошибка подтверждения кода. Пожалуйста, проверьте код и попробуйте снова.'));
      console.log(error);
    }
  };
  

  return (
    <View style={styles.container}>
      <Text style={styles.logo}>Me</Text>
      <Text style={styles.title}>Создать аккаунт</Text>
      <TextInput
        style={[styles.input, {outline: 'none'}, !isUsernameValid ? { borderColor: 'red' } : {}]}
        placeholder="Имя пользователя"
        placeholderTextColor={styles.input.color}
        value={registrationUsername}
        onChangeText={handleUsernameChange}
      />
      {!isUsernameValid && !isFormSubmitted && (
        <Text style={styles.errorText}>Имя пользователя должно содержать 3-20 букв или цифр.</Text>
      )}
      <TextInput
        style={[styles.input,{outline: 'none'},!isPasswordValid ? { borderColor: 'red' } : {}]}
        placeholder="Пароль"
        secureTextEntry={true}
        placeholderTextColor={styles.input.color}
        value={registrationPassword}
        onChangeText={handlePasswordChange}
      />
      {!isPasswordValid && !isFormSubmitted && (
        <Text style={styles.errorText}>Пароль должен содержать как минимум 8 символов, включая заглавную букву, строчную букву и цифру.</Text>
      )}
      <TextInput
        style={[styles.input, {outline: 'none'}, !isEmailValid ? { borderColor: 'red' } : {}]}
        placeholder="Почта"
        placeholderTextColor={styles.input.color}
        value={registrationEmail}
        onChangeText={handleEmailChange}
      />
      {!isEmailValid && !isFormSubmitted && (
        <Text style={styles.errorText}>Введите действительный email.</Text>
      )}
      {isFormSubmitted && (
        <TextInput
          style={[styles.input, {outline: 'none'}, !isCodeValid ? { borderColor: 'red' } : {}]}
          placeholder="Введите код из почты"
          placeholderTextColor={styles.input.color}
          value={registrationCode}
          onChangeText={handleCodeChange}
        />
      )}
      {!isCodeValid && isFormSubmitted && (
        <Text style={styles.errorText}>Код подтверждения должен содержать 6 цифр.</Text>
      )}
      {generalError && <Text style={styles.errorText}>{generalError}</Text>}
      <TouchableOpacity onPress={closeModal}>
        <Text style={styles.registerText}>Вернуться</Text>
      </TouchableOpacity>
      {!isFormSubmitted ? (
        <TouchableOpacity
          onPress={register}
          style={styles.button}
          activeOpacity={0.7}
          disabled={!registrationUsername || !registrationPassword || !registrationEmail || !isUsernameValid || !isPasswordValid || !isEmailValid}
        >
          <Text style={styles.buttonText}>Отправить код</Text>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity
          onPress={verifyCode}
          style={styles.button}
          activeOpacity={0.7}
          disabled={!registrationCode || !isCodeValid}
        >
          <Text style={styles.buttonText}>Регистрация</Text>
        </TouchableOpacity>
      )}
    </View>
  );
}