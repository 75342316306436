import CardList from '../components/Organisms/CardList/CardList'

export default function Dashboard(setIsModalVisible) {
  return (
    <>
      <CardList />
    </>
  );
}

