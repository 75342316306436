import { StyleSheet } from 'react-native';

const createStyles = (colorScheme) => StyleSheet.create({
  component: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: colorScheme === 'dark' ? 'black' : 'white',
    top: '6%',
    bottom: 0,
    height: '100%',
    width: '100%',
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: 18,
    resizeMode: 'stretch',
  },
  // sectionList: {
  // },
  openedCard: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colorScheme === 'dark' ? 'black' : 'white',
    marginTop: '10%',
  },
  qrCodeViewPosition: {
    marginTop:'10%'
  },
  editCardContainer: {
    marginTop:'12%',
    flex: 1,
    justifyContent: 'center',
  },
  blurView: {
    width: '100%',
    height: '100%',
  },
  editCardView: {
    backgroundColor: colorScheme === 'dark' ? 'black' : 'white',
    width: '100%',
    height: '100%',
  },
  openedCardQr: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15%',
    marginBottom: '5%',
  },
  paragraphText: {
    color: 'grey',
    top: '9%',
    letterSpacing: .56,
  },
  scrollView: {
    // overflow: 'scroll',
    // height: '100%',
    backgroundColor: colorScheme === 'dark' ? 'rgba(0,0,0,0.555)' : 'rgba(255,255,255,0.555)',
  },
  closeButton: {
    fontSize: 20,
    fontWeight: 'bold',
    borderWidth: 1,
    borderColor: 'black',
    color: 'black',
    padding: 20,
    marginRight: 5,
    borderRadius: 12,
    backgroundColor: 'orange',
  },
  phoneButton: {
    fontSize: 20,
    fontWeight: 'bold',
    borderWidth: 1,
    borderColor: colorScheme === 'dark' ? 'black' : 'white',
    color: 'white',
    padding: 20,
    marginLeft: 5,
    borderRadius: 12,
    backgroundColor: '#70d94a',
    shadowColor: "lightgreen",
    shadowOffset: {
      width: 0,
      height: 15,
    },
    shadowOpacity: 0.5,
    shadowRadius: 68,
  },
  smsButton: {
    fontSize: 20,
    borderWidth: 1,
    borderColor: colorScheme === 'dark' ? 'black' : 'white',
    fontWeight: 'bold',
    color: 'white',
    padding: 20,
    marginLeft: 5,
    borderRadius: 12,
    backgroundColor: '#519951',
    shadowColor: colorScheme === 'dark' ? 'purple' : 'blue',
    shadowOffset: {
      width: 0,
      height: 15,
    },
    shadowOpacity: colorScheme === 'dark' ? 0.23 : 0.11,
    shadowRadius: 68,
  },
  showQrCodeButton: {
    fontSize: 22,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: colorScheme === 'dark' ? 'black' : 'white',
    fontWeight: 'bold',
    color: 'rgba(233, 222, 255, .64)',
    padding: 20,
  },
  deleteButton: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'white',
    padding: 20,
    borderRadius: 12,
    shadowColor: colorScheme === 'dark' ? 'darkgrey' : 'darkgrey',
    shadowOffset: {
      width: 0,
      height: 15,
    },
    shadowOpacity: 0.11,
    shadowRadius: 68,
  },
  qrCode: {
    marginTop: 10,
    marginBottom: '15%',
    borderRadius: 12,
    shadowColor: colorScheme === 'dark' ? 'blue' : 'black',
    shadowOffset: {
      width: 0,
      height: 15,
    },
    shadowOpacity: colorScheme === 'dark' ? 0.23 : 0.17,
    shadowRadius: 68,
  },
  qrCodeButtonView: {
    marginBottom: '10%',
    borderRadius: 12,
    shadowColor: colorScheme === 'dark' ? 'blue' : 'black',
    shadowOffset: {
      width: 0,
      height: 15,
    },
    shadowOpacity: colorScheme === 'dark' ? 0.17 : 0.17,
    shadowRadius: 68,
  },
  cardOpened: {
    right: '0%',
    width: '100%',
  },
  bgCardOpened: {
    backgroundColor: colorScheme === 'dark' ? 'black' : 'white',
    width: '100%',
    height: '100%',
  },
  buttonsRow: {
    flex: 1,
    flexDirection: 'row',
    marginTop: '10%',
    marginBottom: '5%',
    justifyContent: 'space-between',
    gap: '28px'
  },
  buttonsRowQr: {
    flex: 1,
    flexDirection: 'row',
    marginTop: '17.5%',
    marginBottom: '2.5%',
    justifyContent: 'space-between',
    gap: '28px',
  },
  sectionHeader: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginTop: '2%'
  },
  sectionHeaderText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.18)' : 'lightgrey',
  },
  additionalInfo: {
    color: colorScheme === 'dark' ? 'rgba(233, 222, 255, .435)' : 'rgba(0, 0, 0, 0.7)',
    fontSize: 20,
    lineHeight: '13px',
    letterSpacing: .43,
    fontWeight: '400',
  },
  removeButton: {
    marginTop: '82%',
    opacity: .7,
    borderRadius: 19,
    borderWidth: 1,
    borderColor: 'rgba(70, 61, 103, 0.3)',
  },
  bottomButtons: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  qrCodeTitle: {
    fontSize: 40,
    fontWeight: "600",
    textAlign: 'left',
    marginLeft: '2%',
    marginTop: '2%',
    color: colorScheme === 'dark' ? 'rgba(233, 222, 255, .56)' : 'rgba(1,14,36, 0.77)',
  },
  editCardTitle: {
    fontSize: 33,
    fontWeight: "600",
    lineHeight: 33,
    textAlign: 'left',
    marginLeft: '2%',
    marginTop: '0%',
    color: colorScheme === 'dark' ? 'rgba(233, 222, 255, .22)' : 'rgba(1,14,36, 0.77)',
  },
  buttonNew: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    borderColor: 'rgba(70, 61, 103, 0.3)',
  },
  buttonNewShare: {
    marginTop: '15%',
    backgroundColor: 'orange',
    paddingLeft: '15px',
    paddingRight: '15px',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    borderWidth: 8,
    borderColor: 'orange',
    padding: 10,
  },
  buttonNewShareText: {
    color: 'black',
    fontWeight: '600',
    letterSpacing: '-1px',
    // paddingTop: '12px',
    // paddingBottom: '12px',
    fontSize: 27,
    marginLeft: '6%',
  },
  qrCode: {
    backgroundColor: 'rgba(70, 61, 103, 0.17)',
    borderRadius: 24,
    padding: 20,
  },
  infoRow: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    // marginBottom: "4%",
    justifyContent: 'center',
  },
  backgroundImageGif: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: 18,
    resizeMode: 'cover',
    opacity: colorScheme === 'dark' ? 0.056 : 0.056,
  },
});

export default createStyles;