export const SET_LOGIN_USERNAME = 'SET_LOGIN_USERNAME';
export const SET_LOGIN_PASSWORD = 'SET_LOGIN_PASSWORD';
export const SET_REGISTRATION_EMAIL = 'SET_REGISTRATION_EMAIL';
export const SET_REGISTRATION_USERNAME = 'SET_REGISTRATION_USERNAME';
export const SET_REGISTRATION_PASSWORD = 'SET_REGISTRATION_PASSWORD';
export const SET_REGISTRATION_CODE = 'SET_REGISTRATION_CODE';
export const SET_IS_CODE_SENT = 'SET_IS_CODE_SENT';
export const SET_MODAL_LOGIN_VISIBLE = 'SET_MODAL_LOGIN_VISIBLE';
export const SET_MODAL_REGISTER_VISIBLE = 'SET_MODAL_REGISTER_VISIBLE';
export const SET_MODAL_FORGOT_PASSWORD_VISIBLE = 'SET_MODAL_FORGOT_PASSWORD_VISIBLE';
export const SET_MODAL_ADD_CARD_VISIBLE = 'SET_MODAL_ADD_CARD_VISIBLE';
export const SET_RESET_PASSWORD_PASSWORD = 'SET_RESET_PASSWORD_PASSWORD';
export const SET_RESET_PASSWORD_CONFIRM_PASSWORD = 'SET_RESET_PASSWORD_CONFIRM_PASSWORD';
export const SET_MODAL_NAME = 'SET_MODAL_NAME';
export const SET_MODAL_PHONE = 'SET_MODAL_PHONE';
export const SET_MODAL_COMPANY = 'SET_MODAL_COMPANY';
export const SET_MODAL_POSITION = 'SET_MODAL_POSITION';
export const SET_MODAL_ADDRESS = 'SET_MODAL_ADDRESS';
export const SET_MODAL_EMAIL = 'SET_MODAL_EMAIL';
export const SET_MODAL_WEBSITE = 'SET_MODAL_WEBSITE';
export const SET_MODAL_LOGO = 'SET_MODAL_LOGO';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_CARDS = 'SET_CARDS';
export const SET_USERNAME_VALID = 'SET_USERNAME_VALID';
export const SET_PASSWORD_VALID = 'SET_PASSWORD_VALID';
export const SET_EMAIL_VALID = 'SET_EMAIL_VALID';
export const SET_CODE_VALID = 'SET_CODE_VALID';
export const SET_GENERAL_ERROR = 'SET_GENERAL_ERROR';
export const SET_IS_FORM_SUBMITTED = 'SET_IS_FORM_SUBMITTED';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';
export const SET_SHOW_QR_CODE = 'SET_SHOW_QR_CODE';
export const SET_SHOW_QR_MODAL = 'SET_SHOW_QR_MODAL';
export const SET_REFRESHING = 'SET_REFRESHING';
export const SET_MODAL_EDIT_CARD_VISIBLE = 'SET_MODAL_EDIT_CARD_VISIBLE';

export function setLoginUsername(username) {
  return { type: SET_LOGIN_USERNAME, payload: username };
}

export function setLoginPassword(password) {
  return { type: SET_LOGIN_PASSWORD, payload: password };
}

export function setRegistrationEmail(email) {
  return { type: SET_REGISTRATION_EMAIL, payload: email };
}

export function setRegistrationUsername(username) {
  return { type: SET_REGISTRATION_USERNAME, payload: username };
}

export function setRegistrationPassword(password) {
  return { type: SET_REGISTRATION_PASSWORD, payload: password };
}

export function setRegistrationCode(code) {
  return { type: SET_REGISTRATION_CODE, payload: code };
}

export function setIsCodeSent(isCodeSent) {
  return { type: SET_IS_CODE_SENT, payload: isCodeSent };
}

export function setModalLoginVisible(isVisible) {
  return { type: SET_MODAL_LOGIN_VISIBLE, payload: isVisible };
}

export function setModalRegisterVisible(isVisible) {
  return { type: SET_MODAL_REGISTER_VISIBLE, payload: isVisible };
}

export function setModalForgotPasswordVisible(isVisible) {
  return { type: SET_MODAL_FORGOT_PASSWORD_VISIBLE, payload: isVisible };
}

export function setModalAddCardVisible(isVisible) {
  return { type: SET_MODAL_ADD_CARD_VISIBLE, payload: isVisible };
}

export function setResetPasswordPassword(password) {
  return { type: SET_RESET_PASSWORD_PASSWORD, payload: password };
}

export function setResetPasswordConfirmPassword(confirmPassword) {
  return { type: SET_RESET_PASSWORD_CONFIRM_PASSWORD, payload: confirmPassword };
}

export function setModalName(name) {
  return { type: SET_MODAL_NAME, payload: name };
}

export function setModalPhone(phone) {
  return { type: SET_MODAL_PHONE, payload: phone };
}

export function setModalCompany(company) {
  return { type: SET_MODAL_COMPANY, payload: company };
}

export function setModalPosition(position) {
  return { type: SET_MODAL_POSITION, payload: position };
}

export function setModalAddress(address) {
  return { type: SET_MODAL_ADDRESS, payload: address };
}

export function setModalEmail(email) {
  return { type: SET_MODAL_EMAIL, payload: email };
}

export function setModalWebsite(website) {
  return { type: SET_MODAL_WEBSITE, payload: website };
}

export function setModalLogo(logo) {
  return { type: SET_MODAL_LOGO, payload: logo };
}

export function setIsLoading(isLoading) {
  return { type: SET_IS_LOADING, payload: isLoading };
}

export function setCards(cards) {
  return { type: SET_CARDS, payload: cards };
}

export function setUsernameValid(isValid) {
  return { type: SET_USERNAME_VALID, payload: isValid };
}

export function setPasswordValid(isValid) {
  return { type: SET_PASSWORD_VALID, payload: isValid };
}

export function setEmailValid(isValid) {
  return { type: SET_EMAIL_VALID, payload: isValid };
}

export function setCodeValid(isValid) {
  return { type: SET_CODE_VALID, payload: isValid };
}

export function setGeneralError(error) {
  return { type: SET_GENERAL_ERROR, payload: error };
}

export function setIsFormSubmitted(isSubmitted) {
  return { type: SET_IS_FORM_SUBMITTED, payload: isSubmitted };
}

export function setSearchQuery(query) {
  return { type: SET_SEARCH_QUERY, payload: query };
}

export function setSelectedItem(item) {
  return { type: SET_SELECTED_ITEM, payload: item };
}

export function setShowQrCode(show) {
  return { type: SET_SHOW_QR_CODE, payload: show };
}

export function setShowQrModal(show) {
  return { type: SET_SHOW_QR_MODAL, payload: show };
}

export function setRefreshing(isRefreshing) {
  return { type: SET_REFRESHING, payload: isRefreshing };
}

export function setModalEditCardVisible(isVisible) {
  return { type: SET_MODAL_EDIT_CARD_VISIBLE, payload: isVisible };
}