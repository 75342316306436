import { useState } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  useColorScheme,
} from 'react-native';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import {
  setModalForgotPasswordVisible,
  setUsernameValid,
  setPasswordValid,
  setEmailValid,
  setCodeValid,
  setGeneralError,
  setIsFormSubmitted,
} from '../../../src/redux/actions';
import createStyles from './ForgotPasswordStyles';

export default function ForgotPassword() {
  const [username, setUsername] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);

  const dispatch = useDispatch();
  const generalError = useSelector(state => state.generalError);

  const colorScheme = useColorScheme();
  const styles = createStyles(colorScheme);

  const closeModal = () => {
    dispatch(setModalForgotPasswordVisible(false));
  };

  const validateUsername = (username) => {
    return username.length > 0;
  };

  const validateCode = (code) => {
    return code.length === 6;
  };

  const validatePassword = (password) => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/ .test(password);
  };

  const handleUsernameChange = (text) => {
    setUsername(text);
  };

  const handleCodeChange = (text) => {
    setCode(text);
  };

  const handlePasswordChange = (text) => {
    setPassword(text);
  };

  const handleConfirmPasswordChange = (text) => {
    setConfirmPassword(text);
  };

  const sendCode = async () => {
    if (!validateUsername(username)) {
      dispatch(setUsernameValid(false));
      dispatch(setGeneralError('Username cannot be empty.'));
      return;
    }

    try {
      await Auth.forgotPassword(username);
      setIsCodeSent(true);
      dispatch(setGeneralError(''));
    } catch (error) {
      dispatch(setGeneralError('Error sending code. Please check the username and try again.'));
      console.log(error);
    }
  };

  const resetPassword = async () => {
    if (password !== confirmPassword) {
      dispatch(setGeneralError('Passwords do not match.'));
      return;
    }

    if (!validatePassword(password)) {
      dispatch(setPasswordValid(false));
      dispatch(setGeneralError('Password must be at least 8 characters long, with uppercase, lowercase letters, and digits.'));
      return;
    }

    if (!validateCode(code)) {
      dispatch(setCodeValid(false));
      dispatch(setGeneralError('Code must be 6 digits.'));
      return;
    }

    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      closeModal();
    } catch (error) {
      dispatch(setGeneralError('Error resetting password. Please check the code and try again.'));
      console.log(error);
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.logo}>Me</Text>
      <TextInput
        style={[styles.input, {outline: 'none'}, !validateUsername(username) ? { borderColor: 'red' } : {}]}
        placeholder="Никнейм"
        placeholderTextColor={styles.input.color}
        value={username}
        onChangeText={handleUsernameChange}
      />
      {!validateUsername(username) && !isCodeSent && (
        <Text style={styles.errorText}>Username cannot be empty.</Text>
      )}
      {isCodeSent ? (
        <>
          <TextInput
            style={[styles.input, {outline: 'none'}, !validateCode(code) ? { borderColor: 'red' } : {}]}
            placeholder="Код"
            placeholderTextColor={styles.input.color}
            value={code}
            onChangeText={handleCodeChange}
          />
          {!validateCode(code) && <Text style={styles.errorText}>Code must be 6 digits.</Text>}
          <TextInput
            style={[styles.input, {outline: 'none'}, !validatePassword(password) ? { borderColor: 'red' } : {}]}
            placeholder="Новый пароль"
            secureTextEntry={true}
            placeholderTextColor={styles.input.color}
            value={password}
            onChangeText={handlePasswordChange}
          />
          {!validatePassword(password) && <Text style={styles.errorText}>Password must be at least 8 characters long, with uppercase, lowercase letters, and digits.</Text>}
          <TextInput
            style={[styles.input, {outline: 'none'}, confirmPassword !== password ? { borderColor: 'red' } : {}]}
            placeholder="Подтвердить пароль"
            secureTextEntry={true}
            placeholderTextColor={styles.input.color}
            value={confirmPassword}
            onChangeText={handleConfirmPasswordChange}
          />
          {confirmPassword !== password && <Text style={styles.errorText}>Passwords do not match.</Text>}
          <TouchableOpacity
            onPress={resetPassword}
            style={styles.button}
            activeOpacity={0.7}
            disabled={!password || !confirmPassword || !code || !validatePassword(password) || !validateCode(code)}
          >
            <Text style={styles.buttonText}>Сбросить пароль</Text>
          </TouchableOpacity>
        </>
      ) : (
        <TouchableOpacity
          onPress={sendCode}
          style={styles.button}
          activeOpacity={0.7}
          disabled={!username || !validateUsername(username)}
        >
          <Text style={styles.buttonText}>Отправить код</Text>
        </TouchableOpacity>
      )}
      {generalError && <Text style={styles.errorText}>{generalError}</Text>}
      <TouchableOpacity style={{ marginTop: '4%' }} onPress={closeModal}>
        <Text style={styles.registerText}>Вернуться</Text>
      </TouchableOpacity>
    </View>
  );
}